import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/mnt/d/ShapeGanicGatsby/src/components/blog/layout.js";
import { graphql } from "gatsby";
import { getImage } from "gatsby-plugin-image";
import Disclosure from "../../../components/blog/legal/affiliateMarketing.de.mdx";
import BlogOverview from "../../../components/blog/overview/blogOverview.de.mdx";
import Layout from "../../../components/blog/layout.de";
export const query = graphql`
  {
    mdx(slug: { eq: "welche-app-ist-kompatibel-mit-fitbit/index.de" }) {
      frontmatter {
        main_image {
          publicURL
        }
        image_introduction {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
          }
        }
        image_introduction_alt
        image_introduction_desc
        image_introduction_credit_text
        image_introduction_credit_link
        image_first {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
          }
        }
        image_first_alt
        image_second {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
          }
        }
        image_second_alt
        image_third {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
          }
        }
        image_third_alt
        image_fourth {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
          }
        }
        image_fourth_alt
        date(formatString: "D. MMMM YYYY", locale: "de-DE")
      }
      fields {
        readingTime {
          minutes
        }
      }
    }
  }
`;
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const SEO = makeShortcode("SEO");
const Title = makeShortcode("Title");
const Image = makeShortcode("Image");
const Link = makeShortcode("Link");
const AffiliateLink = makeShortcode("AffiliateLink");
const AppImage = makeShortcode("AppImage");
const AppPromotion = makeShortcode("AppPromotion");
const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = Layout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <SEO lang={props.pageContext.frontmatter.lang} description={props.pageContext.frontmatter.summary} title={props.pageContext.frontmatter.title} keywords={props.pageContext.frontmatter.keywords} imageUrl={props.data.mdx.frontmatter.main_image.publicURL} mdxType="SEO" />
    <Title title={props.pageContext.frontmatter.title} date={props.data.mdx.frontmatter.date} readingTime={props.data.mdx.fields.readingTime.minutes} mdxType="Title" />
    <p>
  <b>{props.pageContext.frontmatter.summary}</b> Um Körperfett ab- oder
  Muskulatur aufzubauen ist es unabdingbar die Kalorienzufuhr zu steuern.
  Entweder muss ein Defizit oder ein Überschuss erzielt werden. Um die
  Kalorienzufuhr zu ermitteln haben sich Kalorienzähler als sehr effektiv
  erwiesen. Doch was ist, wenn auf der anderen Seite dein Kalorienverbrauch
  tagesabhängig ist und von Tag zu Tag unterschiedlich ausfällt? In diesem
  Artikel erfährst du, wie ShapeGanics Fitbit Integration dir genau bei diesem
  Problem helfen kann.
    </p>
    <Image image={getImage(props.data.mdx.frontmatter.image_introduction)} imageAlt={props.data.mdx.frontmatter.image_introduction_alt} imageDesc={props.data.mdx.frontmatter.image_introduction_desc} imageAuthor={props.data.mdx.frontmatter.image_introduction_credit_text} imageLink={props.data.mdx.frontmatter.image_introduction_credit_link} mdxType="Image" />
    <h2>{`Du nutzt Fitbit? Verzichte nicht auf diese Vorteile...`}</h2>
    <p>
  Das Tracking deiner Kalorien ist wichtig, genauso wie das regelmäßige Erfassen
  deines Körpergewichts. Letzteres ist insbesondere wichtig, um die Effektivität
  deiner Ernährung zu ermitteln. Es gibt hierbei jedoch ein Problem. Um dein
  Wunschgewicht zu erreichen sind zwei Faktoren wichtig:{" "}
  <b>a) Die Kalorienzufuhr</b> und <b>b) der Kalorienverbrauch</b>. Möchtest du
  zum Beispiel Muskulatur aufbauen, hast gleichzeitig aber einen zu hohen
  Kalorienverbrauch, so wirst du trotz hoher Kalorienzufuhr eher an Gewicht
  verlieren. Gleichzeitig kannst du nicht nur durch eine reduzierte
  Kalorienzufuhr, sondern auch durch einen erhöhten Kalorienverbrauch Körperfett
  abbauen.
    </p>
    <p>
  Um dich dabei zu unterstützen, stellt dir ShapeGanic eine{" "}
  <Link url={props.pageContext.frontmatter.fitbit_url} mdxType="Link">
    {props.pageContext.frontmatter.fitbit_text}
  </Link>{" "}
  Integration zur Verfügung. Hiermit kannst du auf deine, mit einem{" "}
  <AffiliateLink url={props.pageContext.frontmatter.product_fitbit_url} mdxType="AffiliateLink">
    {props.pageContext.frontmatter.product_fitbit_text}
  </AffiliateLink>{" "}
  erfassten, Aktivitätswerte wie <b>Grund-, Leistungs- und Gesamtumsatz</b>{" "}
  zugreifen. Ebenso erhälst du sowohl lesenden als auch schreibenden Zugriff auf
  deine Fitbit Gewichtsdaten. Du kannst mit Fitbit getrackte Körpergewichtswerte
  also nicht nur sehen, sondern auch hinzufügen, ändern und löschen. Das alles
  wird mit der Fitbit Plattform automatisch abgeglichen.
    </p>
    <h3>{`Du bist nur noch einen Klick entfernt...`}</h3>
    <p>
  Die Verbindung mit Fitbit ist kinderleicht. Du siehst entweder im Dashboard
  Banner, die dich auf diese Funktion hinweisen, oder du gehst in die ShapeGanic
  Einstellungen. Diese sind von fast jedem Screen aus zu erreichen. Wie du auf
  dem folgenden Bild sehen kannst, genügt ein Klick auf den obersten Menüpunkt.
  Anschließend wirst du in einem Browserfenster zu Fitbit weitergeleitet. Hier
  musst du deinen Fitbit Benutzernamen (E-Mailadresse) und Passwort eingeben.
  Anschließend wirst du gefragt, ob du ShapeGanic Zugriff auf die angezeigten
  Daten gewähren möchtest. Bitte wähle alle Kästchen aus und gewähre ShapeGanic
  damit Zugriff auf die entsprechenden Datentypen. Nur so kann eine reibungslose
  Funktionalität gewährleistet werden.
    </p>
    <AppImage image={getImage(props.data.mdx.frontmatter.image_first)} imageAlt={props.data.mdx.frontmatter.image_first_alt} mdxType="AppImage" />
    <p>
  Deine von Fitbit bezogenen Daten werden ausschließlich lokal auf deinem Handy
  gespeichert und werden zu <b>keinem Zeitpunkt</b> an unsere Server oder an
  Dritte gesendet. Datenschutz hat für ShapeGanic oberste Priorität! Du hast
  jederzeit die Möglichkeit ShapeGanic den Zugriff auf deine Fitbit Daten
  nachträglich zu entziehen. Das machst du entweder über die Fitbit-App oder
  über das Fitbit Webportal.
    </p>
    <h2>{`Dieses Feature erleichtert dir dein Leben...`}</h2>
    <p>
  Du befindest dich mitten in einer strengen Diät und hast regelmäßig Hunger.
  Und du denkst an nichts anderes als an eine große Pizza, möchtest aber
  gleichzeitig deine Diät nicht sabotieren. Dann erhöhe doch einfach dein
  tägliches Kalorienlimit für einen Tag z.B. um <b>1000 kcal</b>, indem du sehr
  aktiv bist und einen entsprechend hohen Leistungsumsatz aufweist. ShapeGanic
  bietet dir hierbei die Option, den von Fitbit bezogenen Leistungsumsatz auf
  dein Kalorienlimit sowie proportional auf deine Makronährstofflimits
  hinzufügen. So sind deine Kalorienlimits nicht statisch, sondern passen sich
  automatisch deinem täglichen Aktivitätsniveau an!
    </p>
    <AppImage image={getImage(props.data.mdx.frontmatter.image_second)} imageAlt={props.data.mdx.frontmatter.image_second_alt} mdxType="AppImage" />
    <p>
  Mit diesem adaptiven Ansatz wird deine Diät zu einem Kinderspiel und du hast
  die Möglichkeit auch während der Diät "aus der Reihe zu tanzen" und
  gleichzeitig auf Erfolgskurs zu bleiben.
    </p>
    <h2>{`Grund-, Leistungs- und Gesamtumsatz - Alles auf einen Blick`}</h2>
    <p>
  Bei so vielen Zahlen macht eine übersichtliche Darstellung den Unterschied
  zwischen Erfolg und Misserfolg aus! Dein Grund-, Leistungs- und Gesamtumsatz
  wird übersichtlich und mit Abweichung zum Vortag dargestellt. Jeweils als
  Wochen- oder Monatsübersicht erhälst du einen zeitlichen Verlauf der
  jeweiligen Aktivitätswerte. So kannst du dein Aktivitätsniveau sowie deinen
  Kalorienverbrauch für den jeweils ausgewähltem Tag nachvollziehen.
    </p>
    <AppImage image={getImage(props.data.mdx.frontmatter.image_third)} imageAlt={props.data.mdx.frontmatter.image_third_alt} mdxType="AppImage" />
    <p>
  Unterscheidbare Farbcodes und einfache Symbole machen hierbei das Interface
  einfach auf einen Blick verständlich. So ist es dir möglich deine Ernährung
  als auch deine Aktivitätswerte im zeitlichen Verlauf auszuwerten.
    </p>
    <h2>{`Deine Gewichtseinträge an einem Ort`}</h2>
    <p>
  ShapeGanic bietet dir eine einfache Möglichkeit deine Körpergewichtswerte zu
  tracken. Du nutzt aber auch gleichzeitig das Gewichtstagebuch der Fitbit App
  oder verwendest sogar eine Fitbit kompatible Waage? Dann wäre es doch sehr
  praktisch auf alle Informationsquellen von einem Ort aus zugreifen zu können.
    </p>
    <p>
  Mit ShapeGanic kannst du auf deine Fitbit Gewichtseinträge einfach zugreifen.
  Du hast hierbei sowohl lesenden als auch schreibenden Zugriff. Das bedeutet,
  du kannst nicht nur deine Gewichtseinträge, wie in der unteren Abbildung
  gezeigt, betrachten, sondern auch Einträge hinzufügen, ändern oder sogar
  löschen. Diese Ereignisse werden mit Fitbit abgeglichen.
    </p>
    <AppImage image={getImage(props.data.mdx.frontmatter.image_fourth)} imageAlt={props.data.mdx.frontmatter.image_fourth_alt} mdxType="AppImage" />
    <p>
  Du hast damit alles, was du brauchst, um deine Fitnessziele zu erreichen und
  deinem Wunschkörper einen Schritt näher zu kommen. Du kannst sowohl deine
  Ernährung, deine körperliche Aktivität, als auch dein Körpergewicht
  umfangreich auswerten und basierend darauf Optimierungen vornehmen. Und all
  diese Funktionen sind 100% kostenlos und uneingeschränkt nutzbar!
    </p>
    <Disclosure mdxType="Disclosure" />
    <AppPromotion mdxType="AppPromotion" />
    <BlogOverview mdxType="BlogOverview" />


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      